<template>
  <div class="tip-form fill-height">
    <Alert :show="showAlert && !!showError?.message">
      <SvgSprite symbol="icons-alert" size="0 0 14 11" />
      <span>Erreur : {{ showError?.message }}</span>
    </Alert>
    <BackBtn />
    <div class="wrapper wrapper--small p-l">
      <h3 class="mb-m">Ajouter un bon plan</h3>

      <form
        ref="formel"
        @submit.prevent="onSubmit"
        method="POST"
        enctype="multipart/form-data"
      >
        <div class="mb-s">
          <h5 class="mb-xxs">Titre du bon plan *</h5>
          <FormInput name="title" :required="true" />
        </div>

        <label class="m-s">
          <h5 class="mb-xxs">Description *</h5>
          <textarea name="text" required></textarea>
        </label>

        <div class="mb-s">
          <h5 class="mb-xxs">Ajouter un lien</h5>
          <FormInput name="link" placeholder="www." />
        </div>

        <div class="error m-m" v-if="showError?.message">
          <h4>{{ showError.message }}</h4>

          <ul>
            <li v-for="(val, key) in showError?.errors" :key="key">
              {{ val.join(',') }}
            </li>
          </ul>
        </div>

        <Btn class="send mt-s">Je publie</Btn>
      </form>
    </div>

    <Feedback v-model:visible="feedbackVisible" :badge="badgeFeedback">
      <template v-if="loading">
        <h4 class="mb-xxs">Document en cours d'envoi...</h4>
        <p>Ton bon plan est en cours d'envoi...</p>
      </template>

      <template v-else>
        <h4 class="mb-xxs">Merci !</h4>
        <p>
          Ton bon plan a bien été envoyé. Après vérification, il sera publié et
          disponible pour tous les Animateurs et Animatrices.
        </p>
      </template>
    </Feedback>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, reactive } from 'vue'
import { create } from '@/guides/endpoints/tip'
import { useRoute } from 'vue-router'

import Alert from '@/components/Alert.vue'
import BackBtn from '@/components/BackBtn.vue'
import FormInput from '@/components/FormInput.vue'
import Btn from '@/components/Btn.vue'
import Feedback from '@/components/Feedback.vue'

export default defineComponent({
  components: {
    Alert,
    FormInput,
    Btn,
    BackBtn,
    Feedback,
  },
  setup() {
    const route = useRoute()
    const category = computed(() => route?.params?.subcat)
    console.log('😺 SubCat', category.value)

    const showAlert = ref(false)

    const formel = ref()
    const showError = ref({})
    const badgeFeedback = ref('loading')
    const form = reactive({})

    const feedbackVisible = ref(false)
    const loading = ref(false)

    const showFeedback = () => {
      feedbackVisible.value = true
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onError = (error: any) => {
      feedbackVisible.value = false
      showError.value = error

      showAlert.value = true

      window.setTimeout(() => {
        showAlert.value = false
      }, 5000)
    }

    const onSuccess = () => {
      badgeFeedback.value = 'success'
    }

    const onSubmit = () => {
      if (loading.value) {
        return
      }

      showError.value = {}
      loading.value = true
      feedbackVisible.value = true

      const data = new FormData(formel.value)

      data.append('category', category.value as string)

      create(data)
        .then(d => {
          if (d.data?.status === 200) {
            onSuccess()
          } else {
            onError(d.data)
          }
        })
        .catch(() => {
          onError({
            message: !navigator.onLine
              ? 'Il semblerait que tu sois hors connexion. Connecte toi à internet pour envoyer ton formulaire.'
              : "Il semblerait qu'une erreur ce soit glissée quelque part.",
          })
        })
        .finally(() => {
          loading.value = false
        })
    }

    return {
      form,
      feedbackVisible,
      showFeedback,
      onSubmit,
      loading,
      formel,
      showError,
      badgeFeedback,
      showAlert,
      category,
    }
  },
})
</script>

<style lang="scss" scoped>
.tip-form {
  @include form-base;

  background: $c-white;
}
</style>
